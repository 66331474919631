import { ApolloClient } from 'apollo-client';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { appendHeader, cfetch } from '@confluence/network';
import { SSRMeasures, WaterfallMeasures } from '@confluence/action-measures';
import { InlineCommentsQueryAgg } from './queries/InlineCommentsQuery.agggraphql';
import type {
	InlineCommentsQueryAgg as InlineCommentsQueryAggType,
	InlineCommentsQueryVariables,
} from './queries/__types__/InlineCommentsQuery';
import {
	createExperienceAwareFetch,
	isExperienceAwareFetchAlwaysEnabled,
} from './createExperienceAwareFetch';

const experienceAwareFetch = createExperienceAwareFetch();

// appends the operation names as query params, and
// includes them as headers as well
export const createWrappedFetch = (isCcGraphQL: Boolean) => {
	return (uri: string, options: RequestInit) => {
		if (isCcGraphQL) {
			// On client side the uri is /cgraphql
			// However when running on the server side we want to dynamic change the URI
			// So we can route the request to the graphql deployment that closer to the data.
			// AGG host is redirected by Tesseract so we don't need to change the URI
			uri = window.GLOBAL_APOLLO_CLIENT_URI || uri;
		}

		const payload: Array<{
			operationName: string;
			query: string;
			variables: { [key: string]: any };
		}> = [].concat(typeof options.body === 'string' ? JSON.parse(options.body) : []); // concat normalizes into an array

		const operationNames = payload.map(({ operationName, variables }) => {
			// There are various webitem location we want to distinguish them
			if (operationName === 'WebItemLocationQuery' || operationName === 'WebPanelLocationQuery') {
				return `${operationName}:${
					variables?.location || (variables?.locations || []).join(',') || ''
				}`;
			}

			const isInlineCommentsQueryShadowTest = FeatureGates.checkGate(
				'confluence_inline_comments_query_shadow_test',
			);
			if (
				operationName === 'InlineCommentsQuery' &&
				isCcGraphQL &&
				isInlineCommentsQueryShadowTest
			) {
				if ((window as any).__APOLLO_AGG_CLIENT__) {
					void ((window as any).__APOLLO_AGG_CLIENT__ as ApolloClient<object>).query<
						InlineCommentsQueryAggType,
						InlineCommentsQueryVariables
					>({
						query: InlineCommentsQueryAgg,
						variables: {
							pageId: variables?.pageId,
						},
						fetchPolicy: 'network-only',
					});
				}
			}
			return operationName;
		});

		// pass as headers for rate-limiting compatibility
		const operationNamesList = operationNames.join(',');
		if (!options.headers) {
			options.headers = {};
		}
		appendHeader(options.headers, 'X-APOLLO-OPERATION-NAME', operationNamesList);
		options.referrerPolicy = options.referrerPolicy || 'same-origin';

		// At this point the query has lost the structure and formatted back to string
		// However it is different in experimental queries. The AST is always accessible
		// Refer to next/packages/graphql/src/links/ExperimentalLink/schemaLink.ts
		const executeFetch = () => {
			return (
				isExperienceAwareFetchAlwaysEnabled() || payload.some((item) => item.query.includes('@SLA'))
					? experienceAwareFetch
					: cfetch
			)(`${uri}?q=${operationNamesList}`, options);
		};

		const markingPrefix = process.env.REACT_SSR
			? `ssr-app/prepare/preloadQuery/fetch:`
			: 'wf/client-graphql/';
		const markingNames = operationNames.map((name) => {
			name = isCcGraphQL ? name : `agg_${name}`;
			return `${markingPrefix}${name?.replace(/[^\-\.\w]/g, '_')}`;
		});
		if (process.env.REACT_SSR) {
			return SSRMeasures.run(markingNames, executeFetch);
		}
		// Ignore batch queries because they are not deterministic. Don't make sense to show on performance waterfall.
		return operationNames.length > 1
			? executeFetch()
			: WaterfallMeasures.run(markingNames, executeFetch);
	};
};
