/* eslint-disable prefer-template */
import { Route } from '@confluence/route';
import type { RouteMatch } from '@confluence/route';

import { CONTEXT_PATH } from './context';

// Please do NOT remove /*@__PURE__*/ in this file.
// These comments hints Terser (webpack's minifier) to remove the definition if they are not used.
//
// Please do NOT change string concatenation to string interpolation.
// String interpolations are compiled to "".concat("").concat("") because even the latest edge don't support them natively.
// This made minifier give up removing the dead code because it can't ensure the function call won't have side effects.
//
// Please keep these optimizations here and follow the existing one if you need to add new ones.

const contentTransition = (prev: RouteMatch, curr: RouteMatch) =>
	prev.params.contentId !== curr.params.contentId;

const spaceTransition = (prev: RouteMatch, curr: RouteMatch) =>
	prev.params.spaceKey !== curr.params.spaceKey;

export const BASE = /*@__PURE__*/ new Route({
	name: 'BASE',
	pattern: '/',
});

export const WIKI = /*@__PURE__*/ new Route({
	name: 'WIKI',
	pattern: CONTEXT_PATH,
});

export const HOME = /*@__PURE__*/ new Route({
	name: 'HOME',
	ufoName: 'confluence-home',
	pattern: CONTEXT_PATH + '/home/:view?',
});

export const TEMPLATE_GALLERY = /*@__PURE__*/ new Route({
	name: 'TEMPLATE_GALLERY',
	pattern: CONTEXT_PATH + '/templates',
});

export const CALENDAR = /*@__PURE__*/ new Route({
	name: 'CALENDAR',
	pattern: CONTEXT_PATH + '/calendar/mycalendar.action',
});

export const MY_CALENDAR = /*@__PURE__*/ new Route({
	name: 'MY_CALENDAR',
	pattern: CONTEXT_PATH + '/mycalendar',
});

export const QUESTIONS = /*@__PURE__*/ new Route({
	name: 'QUESTIONS',
	pattern:
		CONTEXT_PATH +
		'/plugins/servlet/ac/com.atlassian.confluence.plugins.confluence-questions/globalQuestionsViewer?ac.com.atlassian.confluence.plugins.confluence-questions.pattern=/questions/ask',
});

export const CONTENT_HISTORY = /*@__PURE__*/ new Route({
	name: 'CONTENT_HISTORY',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/history/:contentId(\\d+)/:contentSlug?',
});

export const CONTENT_HISTORY_LEGACY = /*@__PURE__*/ new Route({
	name: 'CONTENT_HISTORY_LEGACY',
	pattern: CONTEXT_PATH + '/pages/viewpreviousversions.action',
});

export const EDIT_PAGE = /*@__PURE__*/ new Route({
	name: 'EDIT_PAGE',
	ufoName: 'edit-page',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/:contentType(pages)/edit/:contentId(\\d+)',
});

export const EDIT_BLOG = /*@__PURE__*/ new Route({
	name: 'EDIT_BLOG',
	ufoName: 'edit-page',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/:contentType(blog)/edit/:contentId(\\d+)',
});

export const EDIT_PAGE_V2 = /*@__PURE__*/ new Route({
	name: 'EDIT_PAGE_V2',
	ufoName: 'edit-page',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/:contentType(pages)/edit-v2/:contentId(\\d+)',
	isTransition: contentTransition,
});

export const EDIT_BLOG_V2 = /*@__PURE__*/ new Route({
	name: 'EDIT_BLOG_V2',
	ufoName: 'edit-page',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/:contentType(blog)/edit-v2/:contentId(\\d+)',
	isTransition: contentTransition,
});

export const EDIT_PAGE_EMBED = /*@__PURE__*/ new Route({
	name: 'EDIT_PAGE_EMBED',
	ufoName: 'edit-page-embed',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/:contentType(pages)/edit-embed/:contentId(\\d+)',
});

export const CREATE_CONTENT = /*@__PURE__*/ new Route({
	name: 'CREATE_CONTENT',
	pattern: CONTEXT_PATH + '/create-content/:contentType',
});

export const CREATE_PAGE = /*@__PURE__*/ new Route({
	name: 'CREATE_PAGE',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/:contentType(pages)/create',
});

export const CREATE_BLOG = /*@__PURE__*/ new Route({
	name: 'CREATE_BLOG',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/:contentType(blog)/create',
});

export const DELETE_PAGE = /*@__PURE__*/ new Route({
	name: 'DELETE_PAGE',
	pattern: CONTEXT_PATH + '/pages/removepage.action?pageId=:contentId(\\d+)',
});

export const ONBOARDING = /*@__PURE__*/ new Route({
	name: 'ONBOARDING',
	pattern: CONTEXT_PATH + '/welcome',
});

export const SPACE_DIRECTORY = /*@__PURE__*/ new Route({
	name: 'SPACE_DIRECTORY',
	pattern: CONTEXT_PATH + '/spaces',
});

export const SPACE_SETTINGS = /*@__PURE__*/ new Route({
	name: 'SPACE_SETTINGS',
	pattern: CONTEXT_PATH + '/spaces/viewspacesummary.action?key=:spaceKey([^&]+)',
});

export const SPACE_SETTINGS_REORDERPAGES = /*@__PURE__*/ new Route({
	name: 'SPACE_SETTINGS_REORDERPAGES',
	pattern: CONTEXT_PATH + '/pages/reorderpages.action?key=:spaceKey([^&]+)',
});

export const SPACE_PERMISSION = /*@__PURE__*/ new Route({
	name: 'SPACE_PERMISSION',
	pattern: CONTEXT_PATH + '/spaces/spacepermissions.action?key=:spaceKey([^&]+)',
});

export const SPACE_OVERVIEW_REDIRECT_LEGACY = /*@__PURE__*/ new Route({
	name: 'SPACE_OVERVIEW_REDIRECT_LEGACY',
	pattern: CONTEXT_PATH + '/spaces/viewspace.action?key=:spaceKey([^&]+)',
});

export const DELETE_SPACE_LEGACY = /*@__PURE__*/ new Route({
	name: 'DELETE_SPACE_LEGACY',
	pattern: CONTEXT_PATH + '/spaces/doremovespace.action?key=:spaceKey([^&]+)',
});

export const SPACE_INDEX = Object.assign(
	/*@__PURE__*/ new Route({
		name: 'SPACE_INDEX',
		pattern: CONTEXT_PATH + '/spaces/:spaceKey',
		/**
		 * /spaces/:spaceKey will incorrectly match /spaces/something.action.
		 * In that case the request should be sent to Monolith.
		 */
		condition: ({ params }) => Boolean(params.spaceKey && !params.spaceKey.endsWith('.action')),
		isTransition: spaceTransition,
	}),
	{
		/**
		 * Used for optimization purposes. See SPACE_OVERVIEW for more detail.
		 */
		HOMEPAGE_ID_QUERY_KEY: 'homepageId',
	},
);

export const SPACE_OVERVIEW = Object.assign(
	/*@__PURE__*/ new Route({
		name: 'SPACE_OVERVIEW',
		ufoName: 'space-overview',
		pattern: CONTEXT_PATH + '/spaces/:spaceKey/overview',
		isTransition: spaceTransition,
	}),
	{
		/**
		 * The key/name of the optional space overview URL query parameter which was
		 * introduced for optimization purposes only and is used by
		 * `@confluence/query-preloaders` to preload space
		 * homepage-related queries.
		 */
		HOMEPAGE_ID_QUERY_KEY: 'homepageId',
	},
);

export const COMPANY_HUB = /*@__PURE__*/ new Route({
	name: 'COMPANY_HUB',
	pattern: CONTEXT_PATH + '/company-hub',
});

export const COMPANY_HUB_ANALYTICS = /*@__PURE__*/ new Route({
	name: 'COMPANY_HUB_ANALYTICS',
	pattern: CONTEXT_PATH + '/company-hub/analytics',
});

export const VIEW_PAGE = /*@__PURE__*/ new Route({
	name: 'VIEW_PAGE',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/pages/:contentId(\\d+)/:contentSlug?',
	isTransition: contentTransition,
});

export const NEXT_VIEW_PAGE = /*@__PURE__*/ new Route({
	name: 'NEXT_VIEW_PAGE',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/next-pages/:contentId(\\d+)/:contentSlug?',
	isTransition: contentTransition,
});

export const BLOG_HOME = /*@__PURE__*/ new Route({
	name: 'BLOG_HOME',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/blog',
});

export const VIEW_BLOG = /*@__PURE__*/ new Route({
	name: 'VIEW_BLOG',
	ufoName: 'blog',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/blog/:contentId(\\d+)/:contentSlug?',
	isTransition: contentTransition,
});

export const WHITEBOARD_HOME = /*@__PURE__*/ new Route({
	name: 'WHITEBOARD_HOME',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/whiteboard',
});

export const WHITEBOARD_BOARD = /*@__PURE__*/ new Route({
	name: 'WHITEBOARD_BOARD',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/whiteboard/:contentId',
});

export const CONTACT_ADMINISTRATOR = /*@__PURE__*/ new Route({
	name: 'CONTACT_ADMINISTRATOR',
	pattern: CONTEXT_PATH + '/contact-administrators',
});

export const ADMIN_SETTINGS = /*@__PURE__*/ new Route({
	name: 'ADMIN_SETTINGS',
	pattern: CONTEXT_PATH + '/admin',
});

export const ADMIN_GLOBAL_AUTOMATION = /*@__PURE__*/ new Route({
	name: 'ADMIN_GLOBAL_AUTOMATION',
	pattern: CONTEXT_PATH + '/admin/automation',
});

export const ADMIN_EDIT_EXTERNAL_SHARING = /*@__PURE__*/ new Route({
	name: 'ADMIN_EDIT_EXTERNAL_SHARING',
	pattern: CONTEXT_PATH + '/admin/public-links',
});

export const ADMIN_FORGE_APPS = /*@__PURE__*/ new Route({
	name: 'ADMIN_FORGE_APPS',
	pattern: CONTEXT_PATH + '/admin/forge?id=:id(.+)',
});

export const ADMIN_NEW_GLOBAL_PERMISSIONS = /*@__PURE__*/ new Route({
	name: 'ADMIN_NEW_GLOBAL_PERMISSIONS',
	pattern: CONTEXT_PATH + '/admin/permissions/global',
});

export const ADMIN_COMPANY_HUB_PERMISSIONS = /*@__PURE__*/ new Route({
	name: 'ADMIN_COMPANY_HUB_PERMISSIONS',
	pattern: CONTEXT_PATH + '/admin/permissions/hub/:tab(groups|users|guests)?',
});

export const ADMIN_COMPANY_HOME_BUILDER = /*@__PURE__*/ new Route({
	name: 'ADMIN_COMPANY_HOME_BUILDER',
	pattern: CONTEXT_PATH + '/admin/look-and-feel/company-home-builder',
});

export const ADMIN_CUSTOMIZE_NAVIGATION = /*@__PURE__*/ new Route({
	name: 'ADMIN_CUSTOMIZE_NAVIGATION',
	pattern: CONTEXT_PATH + '/admin/look-and-feel/customize-navigation',
});

export const ADMIN_NEW_GLOBAL_PERMISSIONS_USER_DETAILS = /*@__PURE__*/ new Route({
	name: 'ADMIN_NEW_GLOBAL_PERMISSIONS_USER_DETAILS',
	pattern: CONTEXT_PATH + '/admin/permissions/global/user/:accountId',
});

export const ADMIN_SPACE_PERMISSIONS = /*@__PURE__*/ new Route({
	name: 'ADMIN_SPACE_PERMISSIONS',
	pattern: CONTEXT_PATH + '/admin/permissions/viewdefaultspacepermissions.action',
});

//new route for Default space perms uplift
export const ADMIN_DEFAULT_SPACE_PERMISSIONS = /*@__PURE__*/ new Route({
	name: 'ADMIN_DEFAULT_SPACE_PERMISSIONS',
	pattern: CONTEXT_PATH + '/admin/permissions/space-permissions/defaults',
});

//new route for All Spaces perms uplift
export const ADMIN_ALL_SPACES_SPACE_PERMISSIONS = /*@__PURE__*/ new Route({
	name: 'ADMIN_ALL_SPACES_SPACE_PERMISSIONS',
	pattern: CONTEXT_PATH + '/admin/permissions/space-permissions/allspaces',
});

export const ADMIN_EDIT_SPACE_PERMISSIONS = /*@__PURE__*/ new Route({
	name: 'ADMIN_EDIT_SPACE_PERMISSIONS',
	pattern: CONTEXT_PATH + '/admin/permissions/editdefaultspacepermissions.action',
});

export const ADMIN_AUDIT_LOG = /*@__PURE__*/ new Route({
	name: 'ADMIN_AUDIT_LOG',
	pattern: CONTEXT_PATH + '/admin/auditlogging.action',
});

export const ADMIN_BETA_FEATURES = /*@__PURE__*/ new Route({
	name: 'ADMIN_BETA_FEATURES',
	pattern: CONTEXT_PATH + '/admin/betafeatures',
});

export const ADMIN_NEW_FEATURES = /*@__PURE__*/ new Route({
	name: 'ADMIN_NEW_FEATURES',
	pattern: CONTEXT_PATH + '/admin/newfeatures',
});

export const ADMIN_LIVE_EDIT = /*@__PURE__*/ new Route({
	name: 'ADMIN_LIVE_EDIT',
	pattern: CONTEXT_PATH + '/admin/liveedit',
});

export const SPACE_CALENDAR = /*@__PURE__*/ new Route({
	name: 'SPACE_CALENDAR',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/calendars?src=sidebar&openAddCalDialog=true',
});

export const SPACE_CALENDARS = /*@__PURE__*/ new Route({
	name: 'SPACE_CALENDARS',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/calendars',
});

export const SPACE_CALENDARS_SUBCALENDAR = /*@__PURE__*/ new Route({
	name: 'SPACE_CALENDARS_SUBCALENDAR',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/calendars/:subCalendarId',
});

export const DISCOVER = /*@__PURE__*/ new Route({
	name: 'DISCOVER',
	pattern: CONTEXT_PATH + '/discover',
});

export const ALL_UPDATES = /*@__PURE__*/ new Route({
	name: 'ALL_UPDATES',
	pattern: CONTEXT_PATH + '/discover/all-updates',
});

export const POPULAR = /*@__PURE__*/ new Route({
	name: 'POPULAR',
	pattern: CONTEXT_PATH + '/discover/popular',
});

export const MY_WORK_HOME = /*@__PURE__*/ new Route({
	name: 'MY_WORK_HOME',
	pattern: CONTEXT_PATH + '/my',
});

export const MY_WORK_DRAFTS = /*@__PURE__*/ new Route({
	name: 'MY_WORK_DRAFTS',
	pattern: CONTEXT_PATH + '/my/drafts',
});

export const MY_WORK_RECENT = /*@__PURE__*/ new Route({
	name: 'MY_WORK_RECENT',
	pattern: CONTEXT_PATH + '/my/recent-work',
});

export const MY_RECENTLY_VISITED = /*@__PURE__*/ new Route({
	name: 'MY_RECENTLY_VISITED',
	pattern: CONTEXT_PATH + '/my/recently-visited',
});

export const MY_SAVED_FOR_LATER = /*@__PURE__*/ new Route({
	name: 'MY_SAVED_FOR_LATER',
	pattern: CONTEXT_PATH + '/my/saved-for-later',
});

export const RECENT_PAGES = /*@__PURE__*/ new Route({
	name: 'RECENT_PAGES',
	pattern: CONTEXT_PATH + '/recent',
});

export const RECENT_VISITED = /*@__PURE__*/ new Route({
	name: 'RECENT_VISITED',
	pattern: CONTEXT_PATH + '/recent/visited',
});

export const RECENT_DRAFTS = /*@__PURE__*/ new Route({
	name: 'RECENT_DRAFTS',
	pattern: CONTEXT_PATH + '/recent/draft',
});

export const RECENT_SAVED = /*@__PURE__*/ new Route({
	name: 'RECENT_SAVED',
	pattern: CONTEXT_PATH + '/recent/saved',
});

// Note content id here can be ee76872e-09c5-4ec7-9100-2104af429c6a
export const TEMPLATE_MIGRATION_PREVIEW = /*@__PURE__*/ new Route({
	name: 'TEMPLATE_MIGRATION_PREVIEW',
	pattern: CONTEXT_PATH + '/template/migration/preview?templateId=:contentId([0-9a-z-]+)',
});

// Note content id here can be ee76872e-09c5-4ec7-9100-2104af429c6a
export const EDIT_TEMPLATE = /*@__PURE__*/ new Route({
	name: 'EDIT_TEMPLATE',
	pattern: CONTEXT_PATH + '/templates/edit/:contentId([0-9a-z-]+)',
});

// i.e. pages/templates2/editpagetemplate.action?entityId=3735580
// Note content id here can be ee76872e-09c5-4ec7-9100-2104af429c6a
export const SITE_SETTINGS_EDIT_TEMPLATE_LEGACY = /*@__PURE__*/ new Route({
	name: 'SITE_SETTINGS_EDIT_TEMPLATE_LEGACY',
	pattern: CONTEXT_PATH + '/pages/templates2/editpagetemplate.action',
	condition: ({ query }) => Boolean(query.entityId && Object.keys(query).length === 1),
});

// Note pattern can also be com.atlassian.confluence.plugins.confluence-default-space-content-plugin/spacecontent-personal-checklist for system templates
export const EDIT_TEMPLATE_BLUEPRINT = /*@__PURE__*/ new Route({
	name: 'EDIT_TEMPLATE_BLUEPRINT',
	pattern: CONTEXT_PATH + '/templates/edit/:pluginKey/:moduleKey',
});

export const EDIT_DEFAULT_BLUEPRINT_LEGACY = /*@__PURE__*/ new Route({
	name: 'EDIT_DEFAULT_BLUEPRINT_LEGACY',
	pattern: CONTEXT_PATH + '/plugins/createcontent/edit-template.action',
	condition: ({ query }) =>
		Boolean(query.key && query.contentTemplateRefId && Object.keys(query).length === 2) ||
		Boolean(query.contentTemplateRefId && Object.keys(query).length === 1),
});

export const SITE_SETTINGS_EDIT_SYSTEM_TEMPLATE_LEGACY = /*@__PURE__*/ new Route({
	name: 'SITE_SETTINGS_EDIT_SYSTEM_TEMPLATE_LEGACY',
	pattern: CONTEXT_PATH + '/plugins/createcontent/edit-template.action',
	condition: ({ query }) =>
		Boolean(query.pluginKey && query.moduleKey && Object.keys(query).length === 2),
});

// Note content id here can be ee76872e-09c5-4ec7-9100-2104af429c6a
export const EDIT_TEMPLATE_SPACE = /*@__PURE__*/ new Route({
	name: 'EDIT_TEMPLATE_SPACE',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/templates/edit/:contentId([0-9a-z-]+)',
});

export const CREATE_TEMPLATE = /*@__PURE__*/ new Route({
	name: 'CREATE_TEMPLATE',
	pattern: CONTEXT_PATH + '/templates/create',
});

export const CREATE_TEMPLATE_SITE_LEGACY = /*@__PURE__*/ new Route({
	name: 'CREATE_TEMPLATE_SITE_LEGACY',
	pattern: CONTEXT_PATH + '/pages/templates2/createpagetemplate.action',
});

export const CREATE_TEMPLATE_SPACE_LEGACY = /*@__PURE__*/ new Route({
	name: 'CREATE_TEMPLATE_SPACE_LEGACY',
	pattern: CONTEXT_PATH + '/pages/templates2/createpagetemplate.action',
	condition: ({ query }) => Boolean(query.key && Object.keys(query).length === 1),
});

export const CREATE_TEMPLATE_SPACE = /*@__PURE__*/ new Route({
	name: 'CREATE_TEMPLATE_SPACE',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/templates/create',
});

export const HELLO_WORLD = /*@__PURE__*/ new Route({
	name: 'HELLO_WORLD',
	pattern: CONTEXT_PATH + '/hello-world',
});

export const DASHBOARD_ACTION_LEGACY = /*@__PURE__*/ new Route({
	name: 'DASHBOARD_ACTION_LEGACY',
	pattern: CONTEXT_PATH + '/dashboard.action',
});

export const SPA_ACTION_LEGACY = /*@__PURE__*/ new Route({
	name: 'SPA_ACTION_LEGACY',
	pattern: CONTEXT_PATH + '/spa.action',
});

export const INDEX_ACTION_LEGACY = /*@__PURE__*/ new Route({
	name: 'INDEX_ACTION_LEGACY',
	pattern: CONTEXT_PATH + '/index.action',
});

export const CREATE_PAGE_ACTION_LEGACY = /*@__PURE__*/ new Route({
	name: 'CREATE_PAGE_ACTION_LEGACY',
	pattern: CONTEXT_PATH + '/pages/createpage.action',
});

export const CREATE_BLOG_ACTION_LEGACY = /*@__PURE__*/ new Route({
	name: 'CREATE_BLOG_ACTION_LEGACY',
	pattern: CONTEXT_PATH + '/pages/createblogpost.action',
});

export const CREATE_PAGE_VARIABLES_ACTION_LEGACY = /*@__PURE__*/ new Route({
	name: 'CREATE_PAGE_VARIABLES_ACTION_LEGACY',
	pattern: CONTEXT_PATH + '/pages/createpage-entervariables.action',
});

export const RESUME_DRAFT_LEGACY = /*@__PURE__*/ new Route({
	name: 'RESUME_DRAFT_LEGACY',
	pattern: CONTEXT_PATH + '/pages/resumedraft.action',
});

export const COPY_PAGE_LEGACY = /*@__PURE__*/ new Route({
	name: 'COPY_PAGE_LEGACY',
	pattern: CONTEXT_PATH + '/pages/copypage.action',
});

export const VIEW_PAGE_ACTION_LEGACY = /*@__PURE__*/ new Route({
	name: 'VIEW_PAGE_ACTION_LEGACY',
	pattern: CONTEXT_PATH + '/pages/viewpage.action',
	// viewpage.action should be served from Monolith when it has pageVersion query
	condition: ({ query }) => Boolean(query.pageId && Object.keys(query).length === 1),
});

export const SPACE_OVERVIEW_INDEX_ACTION_LEGACY = /*@__PURE__*/ new Route({
	name: 'SPACE_OVERVIEW_INDEX_ACTION_LEGACY',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/overview/index.action',
});

export const SPACE_INDEX_ACTION_LEGACY = /*@__PURE__*/ new Route({
	name: 'SPACE_INDEX_ACTION_LEGACY',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/index.action',
});

export const SPACE_PAGES = /*@__PURE__*/ new Route({
	name: 'SPACE_PAGES',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/pages',
});

export const SPACE_BLOGS = /*@__PURE__*/ new Route({
	name: 'SPACE_BLOGS',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/blogs',
	isTransition: contentTransition,
});

export const VIEW_BLOG_DATE_LEGACY = /*@__PURE__*/ new Route({
	name: 'VIEW_BLOG_DATE_LEGACY',
	ufoName: 'blog',
	pattern:
		CONTEXT_PATH +
		'/spaces/:spaceKey/blog/:year(\\d+)/:month(\\d+)/:day(\\d+)/:contentId(\\d+)/:contentSlug?',
	isTransition: contentTransition,
});

export const ACTION_REDIRECT_LEGACY = /*@__PURE__*/ new Route({
	name: 'ACTION_REDIRECT_LEGACY',
	pattern: CONTEXT_PATH + '/spaces/(.*).action',
	condition: ({ query }) => query.key == null,
});

export const ADVANCED_SEARCH_LEGACY = /*@__PURE__*/ new Route({
	name: 'ADVANCED_SEARCH_LEGACY',
	pattern: CONTEXT_PATH + '/dosearchsite.action',
});

export const ADVANCED_SEARCH = /*@__PURE__*/ new Route({
	name: 'ADVANCED_SEARCH',
	pattern: CONTEXT_PATH + '/search',
});

// export const LABELLED_CONTENT =  new /*@__PURE__*/Route({
//   name: "LABELLED_CONTENT",
//   pattern: CONTEXT_pattern + "/label/:spaceKey/:labelName"
// });

export const EXTERNAL_SHARE = /*@__PURE__*/ new Route({
	name: 'EXTERNAL_SHARE',
	pattern: CONTEXT_PATH + '/external/:contentId?/:externalToken',
});

export const PDF_EXPORT_PAGE = /*@__PURE__*/ new Route({
	name: 'PDF_EXPORT_PAGE',
	pattern: CONTEXT_PATH + '/pdf/spaces/:spaceKey/pages/:contentId(\\d+)',
});

export const PDF_EXPORT_SPACE = /*@__PURE__*/ new Route({
	name: 'PDF_EXPORT_SPACE',
	pattern: CONTEXT_PATH + '/pdf/spaces/:spaceKey/pages/overview',
});

export const VIEW_PAGE_MOBILE = /*@__PURE__*/ new Route({
	name: 'VIEW_PAGE_MOBILE',
	pattern: CONTEXT_PATH + '/mobile/spaces/:spaceKey/pages/:contentId(\\d+)/:contentSlug?',
});

export const VIEW_BLOG_MOBILE = /*@__PURE__*/ new Route({
	name: 'VIEW_BLOG_MOBILE',
	pattern:
		CONTEXT_PATH +
		'/mobile/spaces/:spaceKey/blog/:year(\\d+)/:month(\\d+)/:day(\\d+)/:contentId(\\d+)/:contentSlug?',
});

export const PEOPLE_DIRECTORY_SEARCH = /*@__PURE__*/ new Route({
	name: 'PEOPLE_DIRECTORY_SEARCH',
	pattern: '/people/search',
});

export const CONFLUENCE_PEOPLE_DIRECTORY_SEARCH = /*@__PURE__*/ new Route({
	name: 'CONFLUENCE_PEOPLE_DIRECTORY_SEARCH',
	pattern: CONTEXT_PATH + '/people/search',
});

export const MARKETPLACE_FIND_APPS = /*@__PURE__*/ new Route({
	name: 'MARKETPLACE_FIND_APPS',
	pattern: CONTEXT_PATH + '/marketplace(.*)',
});

export const MARKETPLACE_APP_REQUESTS = /*@__PURE__*/ new Route({
	name: 'MARKETPLACE_APP_REQUESTS',
	pattern: CONTEXT_PATH + '/marketplace/manage/app-requests',
});

export const MARKETPLACE_MANAGE_PROMOTIONS = /*@__PURE__*/ new Route({
	name: 'MARKETPLACE_MANAGE_PROMOTIONS',
	pattern: CONTEXT_PATH + '/marketplace/manage/promotions',
});

export const PEOPLE_DIRECTORY = /*@__PURE__*/ new Route({
	name: 'PEOPLE_DIRECTORY',
	pattern: CONTEXT_PATH + '/people(/[\\w-:]+)*',
});

export const SPACE_RESTRICTED_PAGES = /*@__PURE__*/ new Route({
	name: 'SPACE_RESTRICTED_PAGES',
	pattern: CONTEXT_PATH + '/pages/listpermissionpages.action',
});

export const SPACE_ARCHIVED_PAGES = /*@__PURE__*/ new Route({
	name: 'SPACE_ARCHIVED_PAGES',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/archivedpages',
});

export const PAGE_DIFF_BY_VERSION = /*@__PURE__*/ new Route({
	name: 'PAGE_DIFF_BY_VERSION',
	pattern: CONTEXT_PATH + '/pages/diffpagesbyversion.action',
});

export const INSTANCE_ANALYTICS = /*@__PURE__*/ new Route({
	name: 'INSTANCE_ANALYTICS',
	pattern: CONTEXT_PATH + '/analytics',
});

export const INSTANCE_ANALYTICS_PERMISSIONS = new Route({
	name: 'INSTANCE_ANALYTICS_PERMISSIONS',
	pattern: CONTEXT_PATH + '/analytics/permissions',
});

export const INSTANCE_ANALYTICS_PERMISSIONS_PLUGIN = new Route({
	name: 'INSTANCE_ANALYTICS_PERMISSIONS_PLUGIN',
	pattern:
		CONTEXT_PATH +
		'/plugins/servlet/ac/com.addonengine.analytics/analytics-permissions-instance-admin-page?user.isExternalCollaborator=false',
});

export const FEDRAMP_INSTANCE_ANALYTICS_PERMISSIONS_PLUGIN = new Route({
	name: 'FEDRAMP_INSTANCE_ANALYTICS_PERMISSIONS_PLUGIN',
	pattern:
		CONTEXT_PATH +
		'/plugins/servlet/ac/com.addonengine.analytics.moderate/analytics-permissions-instance-admin-page?user.isExternalCollaborator=false',
});

export const SPACE_ANALYTICS = /*@__PURE__*/ new Route({
	name: 'SPACE_ANALYTICS',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/analytics',
});

export const SPACE_ANALYTICS_PLUGIN = /*@__PURE__*/ new Route({
	name: 'SPACE_ANALYTICS_PLUGIN',
	pattern:
		CONTEXT_PATH +
		'/display/:spaceKey/customcontent/list/ac%3Acom.addonengine.analytics%3Aspace-analytics',
});

// TODO - CCIR-11: Remove this entire route and replace with SPACE_PERMISSIONS_SETTINGS and a new
// tab once the Analytics UI migration is complete
export const SPACE_ANALYTICS_PERMISSIONS = new Route({
	name: 'SPACE_ANALYTICS_PERMISSIONS',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/analytics/permissions',
});

export const SPACE_ANALYTICS_PERMISSIONS_PLUGIN = new Route({
	name: 'SPACE_ANALYTICS_PERMISSIONS_PLUGIN',
	pattern:
		CONTEXT_PATH +
		'/plugins/atlassian-connect/space-tools-tab.action?key=:spaceKey&addonKey=com.addonengine.analytics&moduleKey=analytics-permissions-space-tools-tab',
});

export const FEDRAMP_SPACE_ANALYTICS_PLUGIN = /*@__PURE__*/ new Route({
	name: 'FEDRAMP_SPACE_ANALYTICS_PLUGIN',
	pattern:
		CONTEXT_PATH +
		'/display/:spaceKey/customcontent/list/ac%3Acom.addonengine.analytics.moderate%3Aspace-analytics',
});

export const FEDRAMP_SPACE_ANALYTICS_PERMISSIONS_PLUGIN = new Route({
	name: 'FEDRAMP_SPACE_ANALYTICS_PERMISSIONS_PLUGIN',
	pattern:
		CONTEXT_PATH +
		'/plugins/atlassian-connect/space-tools-tab.action?key=:spaceKey&addonKey=com.addonengine.analytics.moderate&moduleKey=analytics-permissions-space-tools-tab',
});

export const PAGE_ANALYTICS = /*@__PURE__*/ new Route({
	name: 'PAGE_ANALYTICS',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/analytics/:contentId(\\d+)/:contentSlug?',
});

export const SPACE_QUESTIONS = /*@__PURE__*/ new Route({
	name: 'SPACE_QUESTIONS',
	pattern:
		CONTEXT_PATH +
		'/display/:spaceKey/customcontent/list/ac%3Acom.atlassian.confluence.plugins.confluence-questions%3Aquestion',
});

export const SPACE_QUESTIONS_NATIVE = /*@__PURE__*/ new Route({
	name: 'SPACE_QUESTIONS_NATIVE',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/questions',
});

export const SPACE_CUSTOM_CONTENT_LIST = /*@__PURE__*/ new Route({
	name: 'SPACE_CUSTOM_CONTENT_LIST',
	pattern: CONTEXT_PATH + '/display/:spaceKey/customcontent/list/:type',
});

export const CUSTOM_CONTENT_VIEW = /*@__PURE__*/ new Route({
	name: 'CUSTOM_CONTENT_VIEW',
	pattern: CONTEXT_PATH + '/display/:spaceKey/customcontent/:contentId(\\d+)',
});

export const SPACE_BULK_TOOLS = /*@__PURE__*/ new Route({
	name: 'SPACE_BULK_TOOLS',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/bulktools',
});

const SPACE_SETTINGS_CONTEXT_PATH = CONTEXT_PATH + '/spaces/:spaceKey/settings';

export const SPACE_SETTINGS_LANDING_PAGE = /*@__PURE__*/ new Route({
	name: 'SPACE_SETTINGS_LANDING_PAGE',
	pattern: SPACE_SETTINGS_CONTEXT_PATH + '/home',
});

export const SPACE_SETTINGS_LANDING_PAGE_ALT = /*@__PURE__*/ new Route({
	name: 'SPACE_SETTINGS_LANDING_PAGE_ALT',
	pattern: SPACE_SETTINGS_CONTEXT_PATH,
});

export const SPACE_SETTINGS_AUTOMATION = /*@__PURE__*/ new Route({
	name: 'SPACE_SETTINGS_AUTOMATION',
	pattern: SPACE_SETTINGS_CONTEXT_PATH + '/automation',
});

export const SPACE_SETTINGS_ARCHIVE_SPACE = /*@__PURE__*/ new Route({
	name: 'SPACE_SETTINGS_ARCHIVE_SPACE',
	pattern: SPACE_SETTINGS_CONTEXT_PATH + '/archiveSpace',
});

export const SPACE_SETTINGS_TRASH_SPACE = /*@__PURE__*/ new Route({
	name: 'SPACE_SETTINGS_TRASH_SPACE',
	pattern: CONTEXT_PATH + '/spaces/removespace.action?key=:spaceKey([^&]+)',
});

export const SPACE_SETTINGS_EDITOR_CONVERSION = /*@__PURE__*/ new Route({
	name: 'SPACE_SETTINGS_EDITOR_CONVERSION',
	pattern: SPACE_SETTINGS_CONTEXT_PATH + '/editorConversion',
});

export const SPACE_SETTINGS_RELATED_PAGES = /*@__PURE__*/ new Route({
	name: 'SPACE_SETTINGS_RELATED_PAGES',
	pattern: SPACE_SETTINGS_CONTEXT_PATH + '/relatedPages',
});

export const SPACE_SETTINGS_EDIT_FEATURES = /*@__PURE__*/ new Route({
	name: 'SPACE_SETTINGS_EDIT_FEATURES',
	pattern: SPACE_SETTINGS_CONTEXT_PATH + '/editFeatures',
});

export const SPACE_SETTINGS_TEMPLATES = /*@__PURE__*/ new Route({
	name: 'SPACE_SETTINGS_TEMPLATES',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/settings/look-and-feel/templates',
});

// i.e. pages/templates2/editpagetemplate.action?entityId=3735580&key=S1
// Note content id here can be ee76872e-09c5-4ec7-9100-2104af429c6a
export const SPACE_SETTINGS_EDIT_TEMPLATE_LEGACY = /*@__PURE__*/ new Route({
	name: 'SPACE_SETTINGS_EDIT_TEMPLATE_LEGACY',
	pattern: CONTEXT_PATH + '/pages/templates2/editpagetemplate.action',
	condition: ({ query }) => Boolean(query.entityId && query.key && Object.keys(query).length === 2),
});

export const SPACE_LOOK_AND_FEEL_SETTINGS = /*@__PURE__*/ new Route({
	name: 'SPACE_LOOK_AND_FEEL_SETTINGS',
	pattern:
		SPACE_SETTINGS_CONTEXT_PATH + '/look-and-feel/:tab(themes|templates|page-layout|pdf-export)',
});

export const SPACE_PERMISSIONS_SETTINGS = /*@__PURE__*/ new Route({
	name: 'SPACE_PERMISSIONS_SETTINGS',
	pattern:
		SPACE_SETTINGS_CONTEXT_PATH +
		'/permissions/:tab(groups|users|guests|anonymous|public-links|analytics)?',
});

export const SPACE_MEMBERS = /*@__PURE__*/ new Route({
	name: 'SPACE_MEMBERS',
	pattern: SPACE_SETTINGS_CONTEXT_PATH + '/members',
});

// appRoutePath is essential here as it used as a vendor app's state
// https://developer.atlassian.com/platform/forge/custom-ui-bridge/view/#createhistory
export const SPACE_FORGE_APPS = /*@__PURE__*/ new Route({
	name: 'SPACE_FORGE_APPS',
	pattern:
		CONTEXT_PATH +
		'/spaces/:spaceKey/apps/:appId([0-9a-z-]+)/:envId([0-9a-z-]+)/:forgeManifestRoute([0-9a-z-]+)/:appRoutePath*',
});

// appRoutePath is essential here as it used as a vendor app's state
// https://developer.atlassian.com/platform/forge/custom-ui-bridge/view/#createhistory
export const GLOBAL_PAGE_FORGE = /*@__PURE__*/ new Route({
	name: 'GLOBAL_PAGE_FORGE',
	pattern:
		CONTEXT_PATH +
		'/apps/:appId([0-9a-z-]+)/:envId([0-9a-z-]+)/:forgeManifestRoute([0-9a-z-]+)/:appRoutePath*',
});

export const SPACE_SETTINGS_FORGE_APPS = /*@__PURE__*/ new Route({
	name: 'SPACE_SETTINGS_FORGE_APPS',
	pattern:
		SPACE_SETTINGS_CONTEXT_PATH +
		'/apps/:appId([0-9a-z-]+)/:envId([0-9a-z-]+)/:forgeManifestRoute([0-9a-z-]+)?/:appRoutePath*',
});

export const SPACE_SETTINGS_CONTENT_STATE = /*@__PURE__*/ new Route({
	name: 'SPACE_SETTINGS_CONTENT_STATE',
	pattern: SPACE_SETTINGS_CONTEXT_PATH + '/contentStatuses',
});

export const SPACE_SETTINGS_DATA_CLASSIFICATION = /*@__PURE__*/ new Route({
	name: 'SPACE_SETTINGS_DATA_CLASSIFICATION',
	pattern: SPACE_SETTINGS_CONTEXT_PATH + '/classifications',
});

export const SPACE_SETTINGS_EXPORT_SPACE_UNAVAILABLE = /*@__PURE__*/ new Route({
	name: 'SPACE_SETTINGS_EXPORT_SPACE_UNAVAILABLE',
	pattern: SPACE_SETTINGS_CONTEXT_PATH + '/exportSpaceUnavailable',
});

export const SPACE_SETTINGS_EXPORT_SPACE = /*@__PURE__*/ new Route({
	name: 'SPACE_SETTINGS_EXPORT_SPACE',
	pattern: CONTEXT_PATH + '/spaces/exportspacewelcome.action?key=:spaceKey([^&]+)',
});

export const SPACE_TRASH = /*@__PURE__*/ new Route({
	name: 'SPACE_TRASH',
	pattern: CONTEXT_PATH + '/pages/viewtrash.action?key=:spaceKey([^&]+)',
});

export const MOBILE_TAKEOVER_PAGE = /*@__PURE__*/ new Route({
	name: 'MOBILE_TAKEOVER_PAGE',
	pattern: CONTEXT_PATH + '/mobileapp',
});

export const DEFAULT_ADMIN_MANAGE_APPS = /*@__PURE__*/ new Route({
	name: 'DEFAULT_ADMIN_MANAGE_APPS',
	pattern: CONTEXT_PATH + '/plugins/servlet/upm',
});

export const SPACE_SETTINGS_TEMPLATE_LIST_PAGE_LEGACY = /*@__PURE__*/ new Route({
	name: 'SPACE_SETTINGS_TEMPLATE_LIST_PAGE_LEGACY',
	pattern: CONTEXT_PATH + '/pages/templates2/listpagetemplates.action?key=:spaceKey([^&]+)',
});

export const ADMIN_TEMPLATES_AND_BLUEPRINTS = /*@__PURE__*/ new Route({
	name: 'ADMIN_TEMPLATES_AND_BLUEPRINTS',
	pattern: CONTEXT_PATH + '/admin/templates-and-blueprints',
});

export const ADMIN_APPLICATION_NAVIGATOR = /*@__PURE__*/ new Route({
	name: 'ADMIN_APPLICATION_NAVIGATOR',
	pattern: CONTEXT_PATH + '/admin/application-navigator',
});

export const CUSTOM_CONTENTS_ON_PAGE_LIST = /*@__PURE__*/ new Route({
	name: 'CUSTOM_CONTENTS_ON_PAGE_LIST',
	pattern: CONTEXT_PATH + '/display/:spaceKey/pages/:contentId(\\d+)/customcontents/:type?',
});

export const ADMIN_ANNOUNCEMENT_BANNER_RENDERER = /*@__PURE__*/ new Route({
	name: 'ADMIN_ANNOUNCEMENT_BANNER_RENDERER',
	pattern: CONTEXT_PATH + '/admin/announcements/:id(.*)?',
});

export const PERMISSION_HELPER = /*@__PURE__*/ new Route({
	name: 'PERMISSION_HELPER',
	pattern: CONTEXT_PATH + '/admin/permission-helper',
});

export const SPACE_MANAGER = /*@__PURE__*/ new Route({
	name: 'SPACE_MANAGER',
	pattern: CONTEXT_PATH + '/admin/space-manager',
});

export const ADMIN_CENTER = /*@__PURE__*/ new Route({
	name: 'ADMIN_CENTER',
	pattern: CONTEXT_PATH + '/admin/center',
});

export const ADMIN_CENTER_SPACE = /*@__PURE__*/ new Route({
	name: 'ADMIN_CENTER_SPACE',
	pattern: SPACE_SETTINGS_CONTEXT_PATH + '/mission-control',
});

export const ADMIN_TEAM_CALENDARS = /*@__PURE__*/ new Route({
	name: 'ADMIN_TEAM_CALENDARS',
	pattern: CONTEXT_PATH + '/admin/teamcalendars',
});

export const ADMIN_CONFIGURATION = /*@__PURE__*/ new Route({
	name: 'ADMIN_CONFIGURATION',
	pattern: CONTEXT_PATH + '/admin/configuration',
});

export const ADMIN_DEFAULT_SPACE_LOGO = /*@__PURE__*/ new Route({
	name: 'ADMIN_DEFAULT_SPACE_LOGO',
	pattern: CONTEXT_PATH + '/admin/default-space-logo',
});

export const ADMIN_HEADER_AND_FOOTER = /*@__PURE__*/ new Route({
	name: 'ADMIN_HEADER_AND_FOOTER',
	pattern: CONTEXT_PATH + '/admin/header-and-footer',
});

export const ADMIN_SECURITY_CONFIGURATION = /*@__PURE__*/ new Route({
	name: 'ADMIN_SECURITY_CONFIGURATION',
	pattern: CONTEXT_PATH + '/admin/security-configuration',
});

export const ADMIN_SPACE_REPORTS = /*@__PURE__*/ new Route({
	name: 'ADMIN_SPACE_REPORTS',
	pattern: CONTEXT_PATH + '/admin/space-reports',
});

export const ADMIN_COLOR_SCHEME = /*@__PURE__*/ new Route({
	name: 'ADMIN_COLOR_SCHEME',
	pattern: CONTEXT_PATH + '/admin/color-scheme',
});

export const ADMIN_CODE_MACRO_CONFIGURATION = /*@__PURE__*/ new Route({
	name: 'ADMIN_CODE_MACRO_CONFIGURATION',
	pattern: CONTEXT_PATH + '/admin/code-macro-configuration',
});

export const ADMIN_TRASHED_SPACES = /*@__PURE__*/ new Route({
	name: 'ADMIN_TRASHED_SPACES',
	pattern: CONTEXT_PATH + '/admin/trashed-spaces',
});

export const ADMIN_IMPORT_SPACES = /*@__PURE__*/ new Route({
	name: 'ADMIN_IMPORT_SPACES',
	pattern: CONTEXT_PATH + '/admin/space-import',
});

export const ADMIN_JIRA_MACRO_REPAIR = /*@__PURE__*/ new Route({
	name: 'ADMIN_JIRA_MACRO_REPAIR',
	pattern: CONTEXT_PATH + '/admin/jira-macro-repair',
});

export const ADMIN_MACRO_USAGE = /*@__PURE__*/ new Route({
	name: 'ADMIN_MACRO_USAGE',
	pattern: CONTEXT_PATH + '/admin/macro-usage',
});

export const ADMIN_MACRO_USAGE_LEGACY = /*@__PURE__*/ new Route({
	name: 'ADMIN_MACRO_USAGE_LEGACY',
	pattern: CONTEXT_PATH + '/admin/pluginusage.action',
});

export const ADMIN_THEMES = /*@__PURE__*/ new Route({
	name: 'ADMIN_THEMES',
	pattern: CONTEXT_PATH + '/admin/themes',
});

export const ADMIN_PDF_EXPORT = /*@__PURE__*/ new Route({
	name: 'ADMIN_PDF_EXPORT',
	pattern: CONTEXT_PATH + '/admin/pdf-export',
});

/**
 * DATABASE_CUSTOM_OVERVIEW is a temporary route used before the final migration
 */
export const DATABASE_CUSTOM_OVERVIEW = /*@__PURE__*/ new Route({
	name: 'DATABASE_CUSTOM_OVERVIEW',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/custom/database/overview',
});

export const DATABASE_CUSTOM_VIEW = /*@__PURE__*/ new Route({
	name: 'DATABASE_CUSTOM_VIEW',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/custom/database/:contentId(\\d+)',
});

export const DATABASE_VIEW = /*@__PURE__*/ new Route({
	name: 'DATABASE_VIEW',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/database/:contentId',
});

export const EMBED_VIEW = /*@__PURE__*/ new Route({
	name: 'EMBED_VIEW',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/embed/:contentId',
});

export const FOLDER_VIEW = /*@__PURE__*/ new Route({
	name: 'FOLDER_VIEW',
	pattern: CONTEXT_PATH + '/spaces/:spaceKey/folder/:contentId',
});

export const ADMIN_BULK_PERMISSIONS = /*@__PURE__*/ new Route({
	name: 'ADMIN_BULK_PERMISSIONS',
	pattern: CONTEXT_PATH + '/admin/permissions/bulk-permissions',
});

export const ADMIN_NOTIFICATIONS = /*@__PURE__*/ new Route({
	name: 'ADMIN_NOTIFICATIONS',
	pattern: CONTEXT_PATH + '/admin/notifications',
});

export const TOPICS = /*@__PURE__*/ new Route({
	name: 'TOPICS',
	pattern: CONTEXT_PATH + '/topics/:topicId',
});
