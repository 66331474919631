import FeatureGates from '@atlaskit/feature-gate-js-client';

import { getValue } from '@confluence/lifted-promise';
import {
	ADMIN_COMPANY_HUB_PERMISSIONS,
	SPACE_PERMISSIONS_SETTINGS,
} from '@confluence/named-routes';
import { AccessStatus, getSessionData } from '@confluence/session-data';

export function getCompanyHubSideNav(routeName: string | null | undefined) {
	if (
		!process.env.REACT_SSR &&
		(routeName === SPACE_PERMISSIONS_SETTINGS.name ||
			routeName === ADMIN_COMPANY_HUB_PERMISSIONS.name) &&
		FeatureGates.checkGate('confluence_frontend_company_hub_ga_customize_nav')
	) {
		// https://hello.atlassian.net/wiki/spaces/~701217e532d6bdc7846948a0f4e8afe31a122/pages/3993181421/Company+Hub+-+Beta+Tag+Removal
		// documents the product and design folks of Company Hub want Hub permissions to be among the site admin screens of the product,
		// specifically next to permissions-related screens such as Global permissions, Space permissions, Analytics permissions, etc in
		// the Security section.
		const sessionData = getValue(getSessionData());

		// But for non-site admins the site admin side nav is pretty much empty and, more importantly, there are no other
		// permissions-related screens there. Then Hub permissions in the site admin side nav can only make sense to site admins.
		if (sessionData?.accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS) {
			return true;
		}
	}

	return false;
}
