import React, { useContext } from 'react';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import {
	ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { CompanyHomeBuilderUpsellPage } from '@confluence/change-edition/entry-points/CompanyHomeBuilderUpsellPage';
import { useCompanyHubPremiumGate } from '@confluence/company-hub-utils/entry-points/useCompanyHubPremiumGate';
import { useIsTryBeforeYouBuyState } from '@confluence/company-hub-utils/entry-points/useIsTryBeforeYouBuyState';
import { LoadablePaint } from '@confluence/loadable';
import type { RouteMatch } from '@confluence/route';
import { getCompanyHubSideNav } from '@confluence/breadcrumbs/entry-points/getCompanyHubSideNav';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { SPAViewContext } from '@confluence/spa-view-context';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { ADMIN_COMPANY_HUB_PERMISSIONS } from '@confluence/named-routes';

const SpacePermissionsSettingsRouteComponent = LoadablePaint({
	loader: async () =>
		import(
			/* webpackChunkName: "loadable-SpacePermissionSettingsRouteComponent" */ './SpacePermissionsSettingsRouteComponent'
		),
});

const DEFAULT_TAB = 'users';

export const SpacePermissionsSettingsRoute = ({
	params: { spaceKey, tab = DEFAULT_TAB },
	name: routeName,
}: RouteMatch) => {
	const isCompanyHub = isCompanyHubSpaceKey(spaceKey);
	const shouldShowPremiumUpsell = useCompanyHubPremiumGate({
		skip: !isCompanyHub,
	});
	const isTryBeforeYouBuyState = useIsTryBeforeYouBuyState({
		skip: !isCompanyHub,
	});
	const { isSiteAdmin } = useContext(SPAViewContext);

	const isNewHubPermissionsRouteEnabled = FeatureGates.checkGate(
		'confluence_frontend_company_hub_simplified_routes',
	);

	if (
		isNewHubPermissionsRouteEnabled &&
		routeName !== ADMIN_COMPANY_HUB_PERMISSIONS.name &&
		isCompanyHub
	) {
		return <Redirection name={ADMIN_COMPANY_HUB_PERMISSIONS.name} />;
	}

	return !isTryBeforeYouBuyState && isSiteAdmin && shouldShowPremiumUpsell ? (
		<CompanyHomeBuilderUpsellPage featureName="companyHubPermissions" />
	) : (
		<SpacePermissionsSettingsRouteComponent spaceKey={spaceKey} tab={tab} />
	);
};

SpacePermissionsSettingsRoute.NAVIGATION_PARAMS = ({
	name,
	params: { spaceKey, tab = DEFAULT_TAB },
}) => ({
	Screen: {
		screenEvent: {
			name:
				tab === 'public-links'
					? 'spacePermissionsPublicLinksScreen'
					: `${tab}SpacePermissionsScreen`,
			id: spaceKey,
		},
		pageState: { spaceKey, routeName: name },
	},
	MainLayout: {
		navView: getSideNav(spaceKey, name),
		spaceKey,
	},
});

const getSideNav = (spaceKey: string | null | undefined, routeName: string) =>
	isCompanyHubSpaceKey(spaceKey) && getCompanyHubSideNav(routeName)
		? ADMIN_HOME_ID
		: CONTAINER_HOME_ID;
